import styled from 'styled-components'

import { HoverPointer } from 'components/HoverPointer'

const Button = styled(HoverPointer)`
  display: inline-block;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.accent};
  color: white;
  padding: ${props => props.theme.rem(.5)} ${props => props.theme.rem(1)};
  margin-left: auto;
  margin-right: ${props => props.theme.rem()};

  &:hover {
    background: ${props => props.theme.accent}ee;
  }
`

Button.defaultProps = {
  theme: {
    accent: '#80c342'
  }
}

export {
  Button
}