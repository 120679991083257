import { Toggle } from 'components/CategoryToggle/Toggle';
import { Container } from 'components/CategoryToggle/Container';

const CategoryToggle = {}
CategoryToggle.Container = Container
CategoryToggle.Toggle = Toggle

export {
  CategoryToggle,
  Toggle,
  Container
}