import styled from 'styled-components'

const NoSelect = styled.div`
${props => props.inline && 'dipslay: inline-block;'}
  user-select: none;
`

NoSelect.defaultProps = {
  inline: false
}

export {
  NoSelect
}