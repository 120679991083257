const required = {
  validate: (v) => {
    switch (typeof v) {
      case 'string': {
        return v.length > 0
      }
      case 'boolean': {
        return v
      }
      default: {
        return true;
      }
    }
  },
  error: 'This field is required.'
}

export {
  required
}