// Vendors
import React from 'react'
import styled from 'styled-components'
import { HoverPointer } from 'components/HoverPointer'
import { Spacer } from 'components/Spacer'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/pro-light-svg-icons';

const ToggleStyled = styled(HoverPointer)`
  flex: 1;
  display: flex;
  align-items: center;
  height: 36px;
  font-size: ${props => props.theme.rem()};
  color: ${props => props.theme.color};
  background: ${props => props.active ? props.theme.primary : 'white'};
  color: ${props => props.active ? props.theme.light : props.theme.text.color};
  font-weight: ${props => props.theme.text.weight};
  border-top: 1px solid ${props => props.theme.primary};
  border-bottom: 1px solid ${props => props.theme.primary};
  border-left: 1px solid ${props => props.theme.primary};
  border-right: 1px solid ${props => props.theme.primary};
  padding: ${props => props.theme.rem(.3125)} ${props => props.theme.rem(.5)};
  &:first-child {
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-top-right-radius: ${props => props.theme.borderRadius};
  }
  &:last-of-type {
    border-top: none;
    border-bottom-left-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};
  }
  transition: .3s background, .3s color, .3s border;
   ${props => props.theme.media.xl(`
   
    justify-content: center;
    
    &:first-child {
      border-top-right-radius: 0px;
      border-bottom-left-radius: ${props.theme.borderRadius};
      border-right: 0px;
    }
    &:last-of-type {
      border-top: 1px solid ${props.theme.primary};
      border-top-right-radius: ${props.theme.borderRadius};
      border-bottom-left-radius: 0px;
      border-right: 1px solid ${props.theme.primary};
    }
   `)}
`

ToggleStyled.defaultProps = {
  active: false,
  theme: {
    primary: '#009ade',
    light: '#edebe9',
    lightSecondary: '#605e5c',
    rem: (n = 1) => `${n * 16}px`,
    borderRadius: `${.25 * 16}px`
  }
}

const Toggle = (props) => {
  const { children, active } = props
  return (
    <ToggleStyled {...props}>
      <Spacer><FontAwesomeIcon icon={ active ? faCheckSquare : faSquare} /></Spacer>
      {children}
    </ToggleStyled>
  )
}

export { 
  Toggle
}