// CONSTANTS
import {
  SET_REQUEST_REASON
} from 'reducer/types/request_reasons'

export const setRequestReason = ({ uuid }) => (dispatch) => {
  dispatch({
    type: SET_REQUEST_REASON,
    uuid
  })
}