import React from 'react'
import styled from 'styled-components'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

const ErrorWrapper = styled.div`${props => `
  width: 100%;
  font-size: 80%;
  margin-top: ${props.theme.rem(.25)};
  color: ${props.theme.orange};
`}`

const Error = ({ children }) => (
  <ErrorWrapper>
    <FontAwesomeIcon icon={faExclamationTriangle} /> {children}
  </ErrorWrapper>
)

export {
  Error
}