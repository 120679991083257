// vendors
import { normalize } from 'normalizr'
// Normalizr schema
import { customer_provider } from 'schema/customer_provider'
import { categoryInput } from 'schema/categoryInput'

import { baseUrl } from 'utils/data/baseUrl'

// CONSTANTS
import {
  GET_INITIALIZE_REQUEST,
  GET_INITIALIZE_SUCCESS,
  GET_INITIALIZE_REJECTED
} from 'reducer/types/intialize'

export const getInitializeRequest = ({api_key}) => (dispatch) => {
  dispatch({
    type: GET_INITIALIZE_REQUEST
  })

  const url = `${baseUrl}${api_key}/schema`

  var Req = new XMLHttpRequest()
  Req.onload = (e) => {
    const json = JSON.parse(Req.response)
    dispatch(getInitializeSuccess(normalize(json, {
      categories: [categoryInput],
      providers: [customer_provider]
    })))
  }
  Req.open('GET', url)
  Req.send()
}

export const getInitializeSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_INITIALIZE_SUCCESS,
    data
  })
}

export const getInitializeRejected = () => (dispatch) => {
  dispatch({
    type: GET_INITIALIZE_REJECTED
  })
}