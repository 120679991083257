import React from 'react'
import styled from 'styled-components'


const NavButton = styled.div`
  display: inline-block;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.accent};
  color: white !important;
  font-size: ${ props => props.theme.rem(.9375)};
  padding: ${props => props.theme.rem(.125)} ${props => props.theme.rem(.75)};
  text-decoration: none !important;

  cursor: ${ props => props.disabled ? `not-allowed` : `pointer` };

  &:hover {
    background: ${props => props.theme.accent}ee;
  }
`

NavButton.defaultProps = {
  theme: {
    accent: '#80c342'
  }
}

export {
  NavButton
}