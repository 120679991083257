// CONSTANTS
import {
  GET_DISABLEDAYS_REQUEST,
  GET_DISABLEDAYS_SUCCESS,
  GET_DISABLEDAYS_REJECTED,  
} from 'reducer/types/disabledays'

import { baseUrl } from 'utils/data/baseUrl'

export const getDisableDaysRequest = ({ api_key, provider, month,year, reason }) => (dispatch) => {
  dispatch({
    type: GET_DISABLEDAYS_REQUEST
  })

  const url = `${baseUrl}${api_key}/unavailabledays?pr=${provider}&month=${month}&year=${year}&unit=DAY&arr=${reason}`
  
  var Req = new XMLHttpRequest()
  Req.onload = (e) => {
    const json = JSON.parse(Req.response)    
    dispatch(getDisableDaysSuccess(json.disableddates))
  }
  Req.open('GET', url)
  Req.send()
}

export const getDisableDaysSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_DISABLEDAYS_SUCCESS,
    data
  })
}

export const getDisableDaysRejected = () => (dispatch) => {
  dispatch({
    type:   GET_DISABLEDAYS_REJECTED,  

  })
}

