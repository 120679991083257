// Vendors
import React from 'react'
import styled from 'styled-components'
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { HoverPointer } from 'components/HoverPointer'

const InputTextWrapper = styled.div`${props => `
  overflow: hidden;
  border-radius: ${props.theme.borderRadius};
  height: 36px;
  flex: 0;
  flex-basis: ${props.theme.rem(15)};
  border: ${props.theme.border()};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s width;
  ${props.error ? `
    border-color: ${props.theme.orange};
    background: ${props.theme.orange}16;
  ` : ''}
`}`

const InputTextInput = styled.input`
  flex: 1;
  background: none;
  color: ${props => props.theme.text.color};
  font-size: ${props => props.theme.rem()};
  height: 100%;
  border: none;
  outline: none;
  padding-left: ${props => props.theme.rem(.5)};
  font-family: ${props => props.theme.text.family};

  &:placeholder-shown {
    font-style: italic;
  }
`

const InputTextIcon = styled(HoverPointer)`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputText = ({ value = '', setValue = () => {}, icon, placeholder='', error }) => {
  return (
    <InputTextWrapper error={error}>
      <InputTextInput
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
      />
      <InputTextIcon onClick={() => setValue('')}>
        <FontAwesomeIcon icon={value.length > 0 ? faTimes : icon} />
      </InputTextIcon>
    </InputTextWrapper>
  )
}