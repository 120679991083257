import styled from 'styled-components'
import { NoSelect } from 'components/NoSelect'

const Header = styled.div`
  background: ${props => props.theme.primary};
  color: white;
  grid-area: header;
  padding: 0 ${props => props.theme.rem()};
  padding-bottom: ${props => props.theme.rem(.5)};
  border-bottom: ${props => props.theme.border()};
  width: 100%;
  top: 0;
  z-index: 1;
`


Header.defaultProps = {
  theme: {
    light: '#edebe9',
    primary: '#009ade',
    rem: (n = 1) => `${n * 16}px`
  }
}

const Brand = styled.div`${props => `
  font-size: ${props.theme.rem(2)};
  color: white;
  font-weight: bold;
  display: block;
  ${props.theme.media.xs(`
    display: none;
  `)}
`}`
Brand.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`
  }
}

const SubHeader = styled(NoSelect)`${props => `
  margin-top: ${props.theme.rem(-.5)};
  font-size: ${props.theme.rem()};
  color: white;
  ${props.theme.media.xs(`
    margin-top: auto;
    padding-top:8px;
  `)}
`}`
SubHeader.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`
  }
}

Header.Brand = Brand;
Header.SubHeader = SubHeader

export {
  Header
}