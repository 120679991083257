import { useReducer } from 'react';

// Funciton: Enhanced reducer function that allows chaining of dispatches
export function useReducerWithThunk(reducer, iState) {
  const [state, dispatch] = useReducer(reducer, iState);

  let customDispatch = (action) => {
    if (typeof action === 'function') {
      action(customDispatch)
    } else {
      dispatch(action)
    }
  }

  return [state, customDispatch]
}
