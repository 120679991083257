// Vendors
import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { AppContext } from 'context/AppContext'

import './FormSection.css'

// Routing
import { RouteContext } from 'context/RouteContext'
import { setRoute } from 'reducer/actions/route'

// Layout
import { FullPageContainer } from 'components/FullPageContainer'
import { NavigationBar } from 'components/NavigationBar'
import { NavButtonBack, Button } from 'components/Button'
import { SectionNav } from 'components/SectionNav'
import { NoSelect } from 'components/NoSelect'

// icons
import { faEnvelope, faSignature, faPhone } from '@fortawesome/pro-light-svg-icons'

// Components
import { InputGroupText } from 'components/InputGroupText'
import { Textarea } from 'components/Textarea'
import { Label } from 'components/Label'

// Validation
import { useInputValidation } from 'utils/hooks/useInputValidation'
import {
  required,
  email as emailRule,
  phone as phoneRule
} from 'utils/validation'
import { sanatizePhone } from 'utils/data/sanatizePhone'

import * as actions from 'reducer/actions/submit'

const PaddedNavigationBar = styled(NavigationBar)`${props => `
  padding: 0 ${props.theme.rem()};
`}`

const FormContainer = styled.div`${props => `
  padding: 0 ${props.theme.rem()};
  flex: 1;
  z-index:1;
`}`

const HiddenMobile = styled.span`${props => `
  ${props.theme.media.xs(`
    display: none;
  `)}
`}`

export const FormSection = () => {

  const {
    api_key,
    activeRequest_reason,
    selectedProvider,
    selectedSlot,
    dispatch
  } = useContext(AppContext)

  const { dispatch: routeDispatch } = useContext(RouteContext)

  const [next, setNext] = useState(false)

  const [submitValidation, setSubmitValidation] = useState(false)

  const [name, setName] = useState('')
  const nameValidation = useInputValidation(name, [required], submitValidation)

  const [email, setEmail] = useState('')
  const emailValidation = useInputValidation(email, [required, emailRule], submitValidation)

  const [phone, setPhone] = useState('')
  const phoneValidation = useInputValidation(phone, [required, phoneRule], submitValidation)

  const [comment, setComment] = useState('')

  useEffect(() => {
    setNext(
      nameValidation.errors.length === 0 && nameValidation.isValidated &&
      emailValidation.errors.length === 0 && emailValidation.isValidated &&
      phoneValidation.errors.length === 0 && phoneValidation.isValidated
    )
  }, [nameValidation, emailValidation, phoneValidation])

  const handleSubmit = () => {
    if(next) {
      dispatch(actions.putSubmitRequest({
        api_key,
        selectedSlot,
        name,
        phone: sanatizePhone(phone),
        email,
        comment,
        provider: selectedProvider,
        activeRequest_reason
      }))
    }
  }

  const FormBack = (
    <NavButtonBack
      onClick={() => routeDispatch(setRoute('/calendar'))}
      type='link'
    >
      Back to Calendar
    </NavButtonBack>
  )
  const FormSubmit = (
    <CSSTransition
      in={next}
      timeout={200}
      classNames="button"
      unmountOnExit
    >
      <Button onClick={handleSubmit} size="sm">
        Submit
      </Button>
    </CSSTransition>
  )

  const FormNavigation = [
    FormBack,
    FormSubmit
  ]

  return (
    <FullPageContainer navigation={FormNavigation}>
      <SectionNav>
        <div>
          <NoSelect as={SectionNav.Title}>Patient Info<HiddenMobile>rmation</HiddenMobile></NoSelect>
        </div>
      </SectionNav>
      <FormContainer>
        <InputGroupText
          label="Full Name"
          placeholder="John Smith"
          icon={faSignature}
          value={name}
          setValue={setName}
          validation={nameValidation}
        />
        <InputGroupText
          label="Email Address"
          placeholder="jsmith@email.com"
          icon={faEnvelope}
          value={email}
          setValue={setEmail}
          validation={emailValidation}
        />
        <InputGroupText
          label="Phone Number"
          placeholder="(555) 555-5555 ext.5"
          icon={faPhone}
          value={phone}
          setValue={setPhone}
          validation={phoneValidation}
        />
        <div>
          <Label>
            Comments
          </Label>
          <Textarea value={comment} onChange={(e) => setComment(e.target.value)} />
        </div>
      </FormContainer>
    </FullPageContainer>
  )
}