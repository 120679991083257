import styled from 'styled-components'

const Primary = styled.span`
  color: ${props => props.theme.primary}
`

Primary.defaultProps = {
  theme: {
    primary: '#009ade'
  }
}

export {
  Primary
}