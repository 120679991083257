import React from 'react'
import { NavButton } from './NavButton'
import styled from 'styled-components'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

const BackButton = styled(NavButton)`${ props => `
  ${buttonType(props)}
`}`

const buttonType = (props) => {
  switch (props.type) {
    case 'link':
      return `
        padding: 0;
        background: transparent;
        color: ${props.theme.primary} !important;
        text-decoration: underline;

        &:hover {
          background: transparent;
          color: ${props.theme.primary} !important;
        }
      `
    default:
      return `
      background: ${props.theme.primary};

      &:hover {
        background: ${props.theme.primary}ee;
      }
    `
  }
}

export const NavButtonBack = (props) => {
  return (
    <BackButton
      {...props}
    >
      <FontAwesomeIcon icon={faChevronLeft} size="sm" /> {props.children}
    </BackButton>
  )
}