import styled from 'styled-components'

const Label = styled.p`${props => `
  font-weight: 600;
  margin-bottom: ${props.theme.rem(.5)};
`}`

Label.defaultProps = {
  theme: {
    rem: (n) => `${n * 16}px`
  }
}

export {
  Label
}