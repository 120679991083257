import { createContext } from 'react';
import moment from 'moment-timezone';

export const appDefaultState = {
  name: '',
  timezone: '',
  api_key: '',
  entities: {
    categories: { },
    request_reasons: { },
    providers: { }
  },
  result: {
    categories:[ ]
  },
  activeCategory: { },
  activeRequest_reason: '',
  selectedProvider: null,
  selectedSlot: { start_time: '', end_time: '' },
  successDialog: {
    show: false,
    date: moment(),
    email: ''
  }
}

export const AppContext = createContext(appDefaultState);