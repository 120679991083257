import styled from 'styled-components'

export const Container = styled.div`
  &:first-child:not(:last-child) {
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-top-right-radius: ${props => props.theme.borderRadius};
  }
  &:last-child {
    border-bottom-left-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};
  }
  ${props => props.theme.media.xl(`
    display: flex;

    &:first-child:not(:last-child) {
      border-bottom-left-radius: ${props => props.theme.borderRadius};
      border-top-right-radius: 0px;
    }
    &:last-child {
    border-top-right-radius: ${props => props.theme.borderRadius};
    border-bottom-left-radius: 0px;
  }
  `)}
`