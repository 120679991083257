import styled from 'styled-components'

const CollapseInner = styled.div`${props => `
  border-top: ${props.theme.border()};
  border-bottom: ${props.theme.border()};
  ${props.theme.media.xl(`
    display: flex;
  `)}
`}`

export { CollapseInner }