import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

import { PoweredBy } from 'components/PoweredBy'

const Container = styled.div`${props => `
  margin: 0 auto;
  padding: ${props.theme.rem(2)} 0;
  width: ${props.theme.rem(32)};
  display: flex;
  height: 100vh;
  flex-direction: column;
`}`

const CompanyName = styled.div`${props => `
  font-family: ${props.theme.text.family};
  font-size: ${props.theme.rem(2)};
  font-weight: bold;
  text-align: center;
`}`

const Title = styled.div`${props => `
  font-family: ${props.theme.text.family};
  color: ${props.theme.primary};
  font-size: ${props.theme.rem(1.5)};
  font-weight: bold;
  text-align: center;
`}`

const Text = styled.div`${props => `
  font-family: ${props.theme.text.family};
  font-weight: ${props.theme.text.weight};
  color: ${props.theme.text.color};
  line-height: 1.6;
`}`

const Heading = styled(Text)`${props => `
  font-weight: bold;
  font-size: ${props.theme.rem(1.125)};
`}`

const TextRow = styled.div`${props => `
  display: flex;
  justify-content: space-between;
  padding: ${props.theme.rem(.625)} 0;
`}`

const ThankYouContainer = styled.div`${props => `
  margin: ${props.theme.rem(2)} 0;
`}`

const HR = styled.div`${props => `
  border-bottom: ${props.theme.border()}
`}`

const Footer = styled.div`
  margin-top: auto;
`
const Receipt = styled(Text)`${props => `
  font-size: 80%;
  margin-bottom: ${props.theme.rem(.5)};
  text-align: center;
  font-style: italic;
`}`


class AppointmentRequest extends React.Component {
  render() {
    const { practice, service, date, time, provider, timezone, email } = this.props;
    return (
      <Container>
        <CompanyName>
          {practice}
        </CompanyName>
        <Title>
          <FontAwesomeIcon icon={faCalendarAlt}/> Appointment Request
        </Title>
        <TextRow>
          <Heading>
            Service
          </Heading>
          <Heading>
            {service}
          </Heading>
        </TextRow>
        <HR />
        <TextRow>
          <Text>
            Provider
          </Text>
          <Text>
            {provider}
          </Text>
        </TextRow>
        <TextRow>
          <Text>
            Date
          </Text>
          <Text>
            {date}
          </Text>
        </TextRow>
        <TextRow>
          <Text>
            Time
          </Text>
          <Text>
            {moment(time.start_time).tz(timezone)?.format('h:mm a')} - {moment(time.end_time).tz(timezone)?.format('h:mm a')}
          </Text>
        </TextRow>
        <HR />
        <ThankYouContainer>
          <Text>
            Thank you for your appointment request with {provider}.  {practice} will reach out to you to verify that your appointment has been accepted.
          </Text>
        </ThankYouContainer>
        <Footer>
          <Receipt>
            A copy of this receipt has been sent to {email}
          </Receipt>
          <PoweredBy style={{background: 'transparent'}}/>
        </Footer>
      </Container>
    )
  }
}

export {
  AppointmentRequest
}