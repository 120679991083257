import styled from 'styled-components'

import { HoverPointer } from 'components/HoverPointer'

const Button = styled(HoverPointer)`
  display: inline-block;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.accent};
  color: white;
  ${props => sizing(props)}
  margin-left: auto;
  margin-right: ${props => props.theme.rem()};

  &:hover {
    background: ${props => props.theme.accent}ee;
  }
`

const sizing = (props) => {
  switch (props.size) {
    case 'sm':
      return `
        font-size: ${ props.theme.rem(.9375)};
        padding: ${props.theme.rem(.125)} ${props.theme.rem(.75)};
      `
    default:
      return `
        font-size: ${ props.theme.rem()};
        padding: ${props.theme.rem(.5)} ${props.theme.rem(1)};
      `
  }
}

Button.defaultProps = {
  theme: {
    accent: '#80c342'
  }
}

export {
  Button
}