import { createContext } from 'react';

const rem = 16;
const light = '#edebe9';
const lightSecondary = '#605e5c';
const orange = '#f15a31';
const green = '#80c342';
const blue = '#009ade';
const grey = '#414850';
const greyLight = '#d9dadc';
const accent = green
const primary = blue

export const themeDefaultState = {
  blue: '#009ade',
  blueSecondary: '#007bc3',
  green: '#80c342',
  orange,
  light,
  lightSecondary,
  red: '#e74c3c',
  redSecondary: '#d01919',
  accent,
  primary,
  grey,
  color: '#212529',
  text: {
    color: grey,
    weight: 100,
    family: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`
  },
  rem: (n = 1) => `${ n * rem}px`,
  border: (color = greyLight, width = 1) => `${width}px solid ${color}`,
  gutter: rem,
  borderRadius: `${.25 * rem}px`,
  shadow: (color = grey) => `${color} 2px 2px 3px 0px`,
  media: {
    xs: (s) => `@media(max-width:${(36 * rem) - 1}px) {
      ${s}
    }`,
    sm: (s) => `@media(min-width:${36 * rem}px) {
      ${s}
    }`,
    md: (s) => `@media(min-width:${48 * rem}px) {
      ${s}
    }`,
    lg: (s) => `@media(min-width:${62 * rem}px) {
      ${s}
    }`,
    xl: (s) => `@media(min-width:${75 * rem}px) {
      ${s}
    }`,
  },
  mediaInverse: {
    xs: (s) => `@media(max-width:${(36 * rem) - 1}px) {
      ${s}
    }`,
    sm: (s) => `@media(max-width:${(48 * rem)-1}px) {
      ${s}
    }`,
    md: (s) => `@media(max-width:${(62 * rem)-1}px) {
      ${s}
    }`,
    lg: (s) => `@media(max-width:${(75 * rem)-1}px) {
      ${s}
    }`,
    xl: (s) => `@media(min-width:${75 * rem}px) {
      ${s}
    }`,
  }
}

export const ThemeContext = createContext(themeDefaultState);