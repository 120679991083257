// CONSTANTS
import {
  SET_CATEGORY
} from 'reducer/types/categories'

export const setCategory = ({uuid}) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY,
    uuid
  })
}