import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  height: ${props => props.theme.rem(4)};
  align-items: center;
  border-top-left-radius: ${props => props.theme.borderRadius};
  border-top-right-radius: ${props => props.theme.borderRadius};
  margin-top: ${props => props.theme.rem(.5)};
  margin-bottom: ${props => props.theme.rem(.5)};
`

Header.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`,
    light: '#edebe9',
    borderRadius: `${.25 * 16}px`
  }
}

export {
  Header
}