import styled from 'styled-components'
import { NoSelect } from 'components/NoSelect'

const HoverPointer = styled(NoSelect)`
  ${props => props.inline && 'dipslay: inline-block;'}
  &:hover {
    cursor: pointer;
  }
`

HoverPointer.defaultProps = {
  inline: false
}

export {
  HoverPointer
}