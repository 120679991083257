import {
  REQUEST,
  SUCCESS,
  REJECTED
} from 'reducer/types/suffix'
import {
  GET,
  SET
} from 'reducer/types/prefix'

// Base CONSTANT for INITIALIZE
export const DISABLEDAYS = 'DISABLEDATES'

export const GET_DISABLEDAYS_REQUEST = `${GET}${DISABLEDAYS}${REQUEST}`
export const GET_DISABLEDAYS_SUCCESS = `${GET}${DISABLEDAYS}${SUCCESS}`
export const GET_DISABLEDAYS_REJECTED = `${GET}${DISABLEDAYS}${REJECTED}`

export const SELECTED_SLOT = 'SELECTED_SLOT'

export const SET_SELECTED_SLOT = `${SET}${SELECTED_SLOT}`