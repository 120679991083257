// Vendors
import React from 'react'
import styled from 'styled-components'
import { Dialog } from 'components/Dialog/Dialog'

const Dimmer = styled.div`${props => `
  display: ${props.show ? 'flex' : 'none'};
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props.theme.grey}a6;
  z-index: 999;
  padding: ${props.theme.rem(.5)};
`}`

const DimmerComponent = ({ provider, practice, request, date, slot, timezone, email, show, setShow }) => {
  return (
    <Dimmer show={show}>
      {
        show && 
        <Dialog
          provider={provider}
          practice={practice}
          request={request}
          date={date}
          slot={slot}
          email={email}
          timezone={timezone}
          setShow={setShow}
        />
      }
    </Dimmer>
  )
}

export {
  DimmerComponent as Dimmer
}