// Vendors
import React from 'react'

// hoc Pages
import { Content } from 'components/Layout'

// Sections
import { QuerySection } from 'components/QuerySection'
import { ProviderSection } from 'components/ProviderSection'
import { CalendarSection } from 'components/CalendarSection'
import { FormSection } from 'components/FormSection'

// This handles the routing for the App
// At this time it only handles basic routing
// TODO:: Add handleing of queries(?) /settings?scope=123af
// TODO:: Add handleing of Parameteres(:id) /profile/54691359
const viewSwitch = (view) => {
  // On first call of the Switch the view will be a string
  // of the full path to render
  // ie: /settings/Appointments

  // Convert the string into an Array for handling of nested routes
  if (typeof view === 'string') {
    view = view.toLocaleLowerCase().split(/(?=\/)/)
  }

  // Shift the first value of the array to handle root route
  // This will give us 2 variables to work with
  // r = Root route
  // view = nested routes
  const r = view.shift()

  // use switch to display specified route
  // pass viewSwitch(view) as a child if nested routes exist
  // ie: /settings/appointment
  // <SettingsWrapper>{viewSwitch(view)}</SettingsWrapper>
  switch (r) {
    case '/providers':  return <Content><ProviderSection /></Content>
    case '/calendar':   return <Content><CalendarSection /></Content>
    case '/form':       return <Content><FormSection /></Content>
    case '/':           
    default:            return <Content><QuerySection /></Content>
  }
}

export default viewSwitch