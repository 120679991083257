// CONSTANTS
import {
  GET_DISABLEDAYS_REQUEST,
  GET_DISABLEDAYS_SUCCESS,
  GET_DISABLEDAYS_REJECTED,  
} from 'reducer/types/disabledays'

export const DisableReducer = (state, action) => {

  switch (action.type) {
    case GET_DISABLEDAYS_REQUEST: {
      return state;
    }
    case GET_DISABLEDAYS_SUCCESS: {
      return action.data;
    }
    case GET_DISABLEDAYS_REJECTED: {
      return state;
    }
    default: {
      return state
    }
  }

}