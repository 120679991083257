import styled from 'styled-components'

const SpacerV = styled.div`
  margin-bottom: ${props =>  props.theme.rem(.5)};
  margin-top: ${props => props.theme.rem(.5)};
`
SpacerV.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`
  }
}

export {
  SpacerV
}