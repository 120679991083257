// Vendor
import React, { useEffect, useContext } from 'react'

// App state/context
import { AppContext } from 'context/AppContext'
import { RouteContext } from 'context/RouteContext'

// viewSwitch
import viewSwitch from 'utils/components/viewSwitch'

import { PoweredBy } from 'components/PoweredBy'

import {
  Layout,
  Header,
} from 'components/Layout'

// Actions
import {
  getInitializeRequest
} from 'reducer/actions/initialize'
import {
  setRequestReason
} from 'reducer/actions/request_reasons'
import { Dimmer } from 'components/Dialog'
import { setSuccessDialogShow } from 'reducer/actions/success_dialog'

// PMOBW
export const PMOBW = () => {

  const { api_key, name, successDialog, dispatch } = useContext(AppContext)
  const { route } = useContext(RouteContext)

  useEffect(() => {
    dispatch(getInitializeRequest({api_key}))
  }, [])

  return (
    <React.Fragment>
        <Layout>
          <Header>
            <Header.Brand>{name}</Header.Brand>
            <Header.SubHeader>Request an Appointment</Header.SubHeader>
          </Header>
            {
              viewSwitch(route)
            }
          <PoweredBy />
        </Layout>
        <Dimmer
          {...successDialog}
          practice={name}
          setShow={() => {
            dispatch(setSuccessDialogShow({ show: false }))
            dispatch(setRequestReason({ uuid: '' }))
          }
          }
        />
    </React.Fragment>
  )
}