import React from 'react'
import { NavButton } from './NavButton'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

export const NavButtonNext = (props) => {
  return (
    <NavButton
      {...props}
    >
      {props.children} <FontAwesomeIcon icon={faChevronRight } size="sm" />
    </NavButton>
  )
}