// Vendors
import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { AppContext } from 'context/AppContext'
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

// Components
import { HoverPointer } from 'components/HoverPointer'
import { Loading } from 'components/Loading'

import {
  SelectContainer,
  OptionsContainer,
  Option,
  PlaceholderText
} from 'components/ReasonSelect'

import {
  setRequestReason
} from 'reducer/actions/request_reasons'

  
const ReasonSelectStyled = styled(HoverPointer)`
  font-size: ${props => props.theme.rem()};
  color: ${props => props.theme.color};
  display: flex;
  align-items: center;
  width: 100%;
  height: ${props => props.theme.rem(2.25)};
  border: 1px solid ${props => props.theme.primary};
  border-top: none;
  padding: 0 ${props => props.theme.rem(1)};
  background: white;
`

const FadeWrapper = styled.div`${props => `
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${props.theme.rem()};
  position: relative !important;
  padding-right: 8px;
  border-right: ${props.theme.border()};
`}`

ReasonSelectStyled.defaultProps = {
  theme: {
    rem: (n) => `${n * 16}px`,
    primary: '#009ade'
  }
}

export const ReasonSelect = ({options = []}) => {

  const {
    activeCategory,
    activeRequest_reason,
    dispatch
  } = useContext(AppContext)
  const [open, setOpen] = useState(false)
    
  const handleSelection = (uuid) => {
    setOpen(false)
    dispatch(setRequestReason({uuid}))
  }
  return (
    <SelectContainer>
      <ReasonSelectStyled onClick={() => setOpen(!open)}>
        <FadeWrapper>
          {
            activeCategory
            ?
              activeRequest_reason
              ?
                activeRequest_reason?.name
              :
                <PlaceholderText>
                  Select a reason for your visit
                </PlaceholderText>
            :
              <Loading />
          }
        </FadeWrapper>
        <FontAwesomeIcon icon={faChevronDown} style={{marginLeft: 'auto'}}/>
      </ReasonSelectStyled>
      {
        open &&
        <OptionsContainer>
          {
            options.map(o => {
              return(
                <Option 
                  key={o.uuid}
                  onClick={() => handleSelection(o.uuid)}
                >
                  {o.name}
                </Option>
              )
            })
          }
        </OptionsContainer>
      }
    </SelectContainer>
  )
}