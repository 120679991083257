import moment from 'moment'

import { baseUrl } from 'utils/data/baseUrl'

import * as submitTypes from 'reducer/types/submit'

// utils
import { compose } from 'utils/compose'

export const putSubmitRequest = ({ api_key, selectedSlot, name, phone, email, comment, provider, activeRequest_reason }) => (dispatch) => {
  dispatch({
    type: submitTypes.PUT_SUBMIT_REQUEST
  })

  const appendTimeSlot = (url) => `${url}?end=${selectedSlot.end_time}&start=${selectedSlot.start_time}`

  const appendName = (url) => `${url}&first_name=${encodeURIComponent(name.split(' ')[0])}&last_name=${encodeURIComponent(name.split(' ')?.[1])}`

  const appendPhone = (url) => `${url}&area_code=${phone.area_code}&phone_number=${phone.phone_number}&extension=${phone.extension}`

  const appendEmail = (url) => `${url}&email=${encodeURIComponent(email)}`

  const appendMessage = (url) => `${url}&message=${encodeURIComponent(comment)}`

  const appendProvider = (url) => `${url}&pr=${provider.uuid}`

  const appendRequestReason = (url) => `${url}&arr=${activeRequest_reason.uuid}`

  const url = compose(
    appendName,
    appendPhone,
    appendEmail,
    appendMessage,
    appendProvider,
    appendRequestReason,
    appendTimeSlot
  )(`${baseUrl}${api_key}/request`)

  var Req = new XMLHttpRequest()
  Req.onload = (e) => {
    dispatch(putSubmitSuccess({
      show: true,
      provider,
      request: activeRequest_reason,
      date: moment(selectedSlot.start_time),
      slot: selectedSlot,
      email
    }))
  }
  Req.open('POST', url)
  Req.send()
}

export const putSubmitSuccess = (successDialog) => (dispatch) => {
  dispatch({
    type: submitTypes.PUT_SUBMIT_SUCCESS,
    successDialog
  })
}

export const putSubmitRejected = () => (dispatch) => {
  dispatch({
    type: submitTypes.PUT_SUBMIT_REJECTED
  })
}