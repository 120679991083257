// Vendors
import React, { useRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone';
import ReactToPrint from 'react-to-print'

import { AppContext } from 'context/AppContext'

// Routing
import { RouteContext } from 'context/RouteContext'
import { setRoute } from 'reducer/actions/route'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faTimes } from '@fortawesome/pro-light-svg-icons';

import { HoverPointer } from 'components/HoverPointer'
import { Button } from 'components/ProviderCard/Private'
import { AppointmentRequest } from 'components/Print'

import { Dimmer } from 'components/Dialog/Dimmer'

import * as dialogActions from 'reducer/actions/success_dialog'

const Dialog = styled.div`${props => `
background: white;
overflow: hidden;
width: 100%;
${Dimmer} & {
  border: ${props.theme.border()};
  width: ${props.theme.rem(32)};
  max-width: 100%;
  margin: 0 auto;
  border-radius: ${props.theme.borderRadius};
}
`}`

const DialogHeader = styled.div`${props => `
  padding: ${props.theme.rem()};
  display: flex;
  justify-content: space-between;
  border-bottom: ${props.theme.border()};
`}`

const DialogBody = styled.div`${props => `
  padding: ${props.theme.rem()};
`}`

const AppointmentDetailsWrapper = styled.div`${props => `
border: ${props.theme.border()};
border-radius: ${props.theme.borderRadius};
display: inline-flex;
margin-bottom: ${props.theme.rem()};
`}`

const AppointmentDetailsIcon = styled.div`${props => `
  background: #f4f3f2;
  border-right: ${props.theme.border()};
  display: flex;
  justify-content: center;
  padding: ${props.theme.rem()};
`}`

const AppointmentDetails = styled.div`${props => `
  padding: ${props.theme.rem()};
`}`

const Detail = styled.div`${props => `
  color: ${props.theme.text.color};
  font-weight: ${props.theme.text.weight};
`}`
const DetailTitle = styled(Detail)`${props => `
  font-size: ${props.theme.rem(1.25)};
  line-height: 1;
  margin-bottom: ${props.theme.rem(.25)};
`}`
const DialogTitle = styled(Detail)`${props => `
  font-size: ${props.theme.rem(1.25)};
`}`
const DialogText = styled(Detail)`${props => `
  margin-bottom: ${props.theme.rem()};
`}`

const ButtonLink = styled.div`${props => `
  text-decoration: underline;
  cursor: pointer;
  color: ${props.theme.primary};
  line-height: 1;
  padding-right: ${props.theme.rem(.75)};
  padding-top: ${props.theme.rem(.5)};
`}`

const DialogComponent = ({ setShow }) => {
  const {
    timezone,
    selectedProvider: provider,
    activeRequest_reason: request,
    selectedSlot: slot,
    name: practice,
    successDialog: {
      date,
      email
    },
    dispatch
  } = useContext(AppContext)

  const { dispatch: routeDispatch } = useContext(RouteContext)

  const cRef = useRef()

  const handleClose = () => {
    dispatch(dialogActions.setSuccessDialogShow(false))
    routeDispatch(setRoute('/'))
  }

  const pageRef = useRef(null)
  const scrollToRef = () => {
    const boundingRect = pageRef.current.getBoundingClientRect()
    const elPos = boundingRect.top + window.pageYOffset - 100

    if (boundingRect.top < 0) {
      window.scrollTo({ top: elPos, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    scrollToRef()
  }, [])

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle ref={pageRef}>
          Thank you!
        </DialogTitle>
        <HoverPointer>
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
        </HoverPointer>
      </DialogHeader>
      <DialogBody>
        <DialogText>
          <strong>{practice}</strong> will reach out to you to verify that your appointment has been accepted.
        </DialogText>
        <AppointmentDetailsWrapper>
          <AppointmentDetailsIcon>
            <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
          </AppointmentDetailsIcon>
          <AppointmentDetails>
            <DetailTitle>
              {request?.name}
            </DetailTitle>
            <Detail>
              {provider?.name}
            </Detail>
            <Detail>
              {date.format('MMMM Do YYYY')}
            </Detail>
            <Detail>
              {moment(slot.start_time).tz(timezone)?.format('h:mm a')} - {moment(slot.end_time).tz(timezone)?.format('h:mm a')}
            </Detail>
          </AppointmentDetails>
          <div>
            <ReactToPrint
              trigger={() => <ButtonLink>Print</ButtonLink>}
              content={() => cRef.current}
            />
            <div style={{ display: 'none' }}>
              <AppointmentRequest
                ref={cRef}
                practice={practice}
                service={request?.name}
                date={date.format('MMMM Do YYYY')}
                time={slot}
                timezone={timezone}
                provider={provider?.name}
                email={email}
              />
            </div>
          </div>
        </AppointmentDetailsWrapper>
        <div>
          <Button onClick={handleClose}>Ok!</Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export {
  DialogComponent as Dialog
}