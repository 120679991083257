// Vendors
import React, { useRef } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone';
import ReactToPrint from 'react-to-print'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faTimes } from '@fortawesome/pro-light-svg-icons';

import { HoverPointer } from 'components/HoverPointer'
import { Button } from 'components/ProviderCard/Private'
import { AppointmentRequest } from 'components/Print'

const Dimmer = styled.div`${props => `
  display: ${props.show ? 'flex' : 'none'};
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props.theme.grey}a6;
  z-index: 999;
`}`

const Dialog = styled.div`${props => `
background: white;
overflow: hidden;
width: 100%;
${Dimmer} & {
  border: ${props.theme.border()};
  ${props.theme.media.md(`
    margin: ${props.theme.rem()};
    border-radius: ${props.theme.borderRadius};
  `)}
}
`}`

const DialogHeader = styled.div`${props => `
  padding: ${props.theme.rem()};
  display: flex;
  justify-content: space-between;
  border-bottom: ${props.theme.border()};
`}`

const DialogBody = styled.div`${props => `
  padding: ${props.theme.rem()};
`}`

const AppointmentDetailsWrapper = styled.div`${props => `
border: ${props.theme.border()};
border-radius: ${props.theme.borderRadius};
display: inline-flex;
margin-bottom: ${props.theme.rem()};
`}`

const AppointmentDetailsIcon = styled.div`${props => `
  background: #f4f3f2;
  border-right: ${props.theme.border()};
  display: flex;
  justify-content: center;
  padding: ${props.theme.rem()};
`}`

const AppointmentDetails = styled.div`${props => `
  padding: ${props.theme.rem()};
`}`

const Detail = styled.div`${props => `
  color: ${props.theme.text.color};
  font-weight: ${props.theme.text.weight};
`}`
const DetailTitle = styled(Detail)`${props => `
  font-size: ${props.theme.rem(1.25)};
  line-height: 1;
  margin-bottom: ${props.theme.rem(.25)};
`}`
const DialotTitle = styled(Detail)`${props => `
  font-size: ${props.theme.rem(1.25)};
`}`
const DialogText = styled(Detail)`${props => `
  margin-bottom: ${props.theme.rem()};
`}`

const ButtonLink = styled.div`${props => `
  text-decoration: underline;
  cursor: pointer;
  color: ${props.theme.primary};
  line-height: 1;
  padding-right: ${props.theme.rem(.75)};
  padding-top: ${props.theme.rem(.5)};
`}`

const DialogComponent = ({provider, practice, request, date, slot, timezone, email, setShow}) => {

  const cRef = useRef()

  return (
    <Dialog>
      <DialogHeader>
        <DialotTitle>
          Appointment Request Details
        </DialotTitle>
        <HoverPointer>
          <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
        </HoverPointer>
      </DialogHeader>
      <DialogBody>
        <DialogText>
          <strong>{practice}</strong> will reach out to you to verify that your appointment has been accepted.
        </DialogText>
        <AppointmentDetailsWrapper>
          <AppointmentDetailsIcon>
            <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
          </AppointmentDetailsIcon>
          <AppointmentDetails>
            <DetailTitle>
              {request.name}
            </DetailTitle>
            <Detail>
              {provider.name}
            </Detail>
            <Detail>
              {date.format('MMMM Do YYYY')}
            </Detail>
            <Detail>
              {moment(slot.start_time).tz(timezone)?.format('h:mm a')} - {moment(slot.end_time).tz(timezone)?.format('h:mm a')}
            </Detail>
          </AppointmentDetails>
          <div>
            <ReactToPrint
              trigger={() => <ButtonLink>Print</ButtonLink>}
              content={() => cRef.current}
            />
            <div style={{display: 'none'}}>
              <AppointmentRequest
                ref={cRef}
                practice={practice}
                service={request.name}
                date={date.format('MMMM Do YYYY')}
                time={slot}
                timezone={timezone}
                provider={provider.name}
                email={email}
              />
            </div>
          </div>
        </AppointmentDetailsWrapper>
        <DialogText>
          A receipt of this request has been sent to <strong>{email}</strong>.
        </DialogText>
        <div>
          <Button onClick={() => setShow(false)}>Ok!</Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

const DimmerComponent = ({ provider, practice, request, date, slot, timezone, show, setShow }) => {
  return (
    <Dimmer show={show}>
      <DialogComponent 
        provider={provider}
        practice={practice}
        request={request}
        date={date}
        slot={slot}
        timezone={timezone}
        setShow={setShow}
      />
    </Dimmer>
  )
}

export {
  DialogComponent as Dialog,
  DimmerComponent as Dimmer
}