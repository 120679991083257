import styled from 'styled-components'

const Layout = styled.div`${
  props => `
  width: 100%;
  border-radius: ${props.theme.borderRadius};
  border: ${props.theme.border()};
  ${
    props.theme.media.md(`
      ${
        props.elevated
        ?
        `
        box-shadow: ${props.theme.shadow()};
        border: none;
        border-left: ${props.theme.border(props.theme.light, 1)};
        `
        :
        `
        border: ${props.theme.border()};
        `
      }
    `)
  }  
  `}`

Layout.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`
  }
}

export {
  Layout
}