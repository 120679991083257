import {
  REQUEST,
  SUCCESS,
  REJECTED
} from 'reducer/types/suffix'
import {
  GET,
  SET
} from 'reducer/types/prefix'

// Base CONSTANT for INITIALIZE
export const SLOTS = 'SLOTS'

export const GET_SLOTS_REQUEST = `${GET}${SLOTS}${REQUEST}`
export const GET_SLOTS_SUCCESS = `${GET}${SLOTS}${SUCCESS}`
export const GET_SLOTS_REJECTED = `${GET}${SLOTS}${REJECTED}`

export const SELECTED_SLOT = 'SELECTED_SLOT'

export const SET_SELECTED_SLOT = `${SET}${SELECTED_SLOT}`