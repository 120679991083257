// Vendors
import React, { useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import Calendar from 'rc-calendar'
import moment from 'moment-timezone'

import './CalendarSection.css'

// Routing
import { RouteContext } from 'context/RouteContext'
import { setRoute } from 'reducer/actions/route'

// Layout
import { FullPageContainer } from 'components/FullPageContainer'
import { NavButtonBack, NavButtonNext } from 'components/Button'
import { SectionNav } from 'components/SectionNav'
import { NoSelect } from 'components/NoSelect'

// Actions
import { AppContext } from 'context/AppContext'
import * as slotActions from 'reducer/actions/slots'
import * as disableActions from 'reducer/actions/disabledays'
import { useReducerWithThunk } from 'utils/reducer'
import { SlotReducer } from 'reducer/SlotReducer'

// Components
import { SlotsContainer } from 'components/SlotsContainer'
import { Help } from 'components/Help'
import { TimeSlot } from 'components/TimeSlot'


// Private Components
import {
  IconContainer
} from 'components/ProviderCard/Private'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarTimes, faUserMd } from '@fortawesome/pro-light-svg-icons';
import { DisableReducer } from 'reducer/DisableReducer'
import { monthsShort } from 'moment'

const HelperText = styled.div`${props => `
  padding: 0 ${props.theme.rem()};
  font-size: ${props.theme.rem(.75)};
  font-style: italic;
  color: ${props.theme.grey};
`}`

const HiddenMobile = styled.span`${props => `
  ${props.theme.media.xs(`
    display: none;
  `)}
`}`

export const CalendarSection = () => {
const disabledays =['2022-05-11','2022-05-12','2022-05-26'];

  const {
    api_key,
    selectedProvider: provider,
    activeRequest_reason,
    selectedSlot,
    dispatch
  } = useContext(AppContext)

  const { dispatch: routeDispatch } = useContext(RouteContext)

  const [next, setNext] = useState(false)

  const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedMonth, setSelectedMonth] = useState(moment())
  
  const defaultSlotSelectionState = {
    slots: [],
    disabledates:[],
    timezone: ''
  }

  const _selectDate = (date) => {    
    setSelectedDate(date);
  }

  const _selectMonth = (date) => {     
    setSelectedMonth(date);    
  }

  const _disableDate = (current) => {
    if (!current) {
      return true;
    }
    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);
    return current.isBefore(date) || !provider?.online_booking_weekdays.includes(current.day()) || !activeRequest_reason?.online_booking_weekdays.includes(current.day()) || Array.from(disabledates).includes(current.format('YYYY-MM-DD'))
    }

  const CalendarStyle = { width: 'auto', boxShadow: 'none' }

  

  const [slots, slotsDispatch] = useReducerWithThunk(SlotReducer, defaultSlotSelectionState) 
  const [disabledates,disabledaysDispatch] = useReducerWithThunk(DisableReducer, defaultSlotSelectionState)  
   

  useEffect(() => {   
    setNext(selectedSlot.start_time !== '')
  }, [selectedSlot])

  useEffect(() => {
    dispatch(slotActions.setSelectedSlot({slot:{ start_time: '', end_time: '' }}))    
    slotsDispatch(slotActions.getSlotsRequest({
      api_key,
      provider: provider.uuid,
      reason: activeRequest_reason.uuid,
      date: selectedDate.format('YYYY-MM-DD')      
    }))
  }, [selectedDate])

  useEffect(() => {
     disabledaysDispatch(disableActions.getDisableDaysRequest({
      api_key,
      provider: provider.uuid,
      reason: activeRequest_reason.uuid,
      month: selectedMonth.format('MM'),
      year: selectedMonth.format('YYYY')
    }))
  }, [selectedMonth])

  const handleSlotSelect = (slot) => {
    dispatch(slotActions.setSelectedSlot({slot}))
  }

  const CalendarBack = (
    <NavButtonBack
      onClick={() => routeDispatch(setRoute('/providers'))}
      type='link'
    >
      Back to Providers
    </NavButtonBack>
  )

  const CalendarNext = (
    <CSSTransition
      in={next}
      timeout={200}
      classNames="button"
      unmountOnExit
    >
      <NavButtonNext onClick={() => routeDispatch(setRoute('/form'))}>
        Next
      </NavButtonNext>
    </CSSTransition>
  )

  const CalendarNavigation = [
    CalendarBack,
    CalendarNext
  ]

  return (
    <FullPageContainer navigation={CalendarNavigation}>
        <SectionNav>
          <div>
          <NoSelect as={SectionNav.Title}><HiddenMobile>Select a </HiddenMobile>Date & Time</NoSelect>
            <NoSelect as={SectionNav.SubTitle}>{provider.name}</NoSelect>
          </div>
          <div style={{height: 64}}>
            <IconContainer image={provider.profile_image_url}>
              {!provider.profile_image_url && <FontAwesomeIcon icon={faUserMd} size="2x" />}
            </IconContainer>
          </div>
        </SectionNav>
        <Calendar
          defaultValue={selectedDate}
          showDateInput={false}
          defaultView='month'          
          onSelect={(date) => _selectDate(date)}         
          disabledDate={(current) => _disableDate(current)}          
          onChange={(month) => _selectMonth(month)}          
          style={CalendarStyle}
        />

        <div style={{ flex: 1, zIndex:1 }}>
          {
            slots.slots.length > 0
              ?
              <React.Fragment>
                  <SlotsContainer error={false}>
                    {
                      slots.slots.map(s => {
                        return (
                          <TimeSlot
                            key={`${provider.uuid}${s.start_time}`}
                            selected={s.start_time === selectedSlot?.start_time}
                            time={s.start_time}
                            timezone={slots.timezone}
                            setTime={() => handleSlotSelect(s)} />
                        )
                      })
                    }
                  </SlotsContainer>
                <HelperText>
                  Times based on {slots.timezone} timezone
                </HelperText>
              </React.Fragment>
              :
              <Help>
                <FontAwesomeIcon icon={faCalendarTimes} size="lg" />
                <Help.Sub>No Availability</Help.Sub>
              </Help>
          }
        </div>
  </FullPageContainer>
  )
}