// Vendors
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { AppContext } from 'context/AppContext'
import { RouteContext } from 'context/RouteContext'
import { CSSTransition } from 'react-transition-group'

import './styles.css'

// Components
import { CategoryToggle } from 'components/CategoryToggle'
import { ReasonSelect } from 'components/ReasonSelect'
import { QueryGroup } from 'components/QueryGroup'
import { FullPageContainer } from 'components/FullPageContainer'
import { Loading } from 'components/Loading'
import { NoSelect } from 'components/NoSelect'
import { NavButtonNext } from 'components/Button'

// Actions
import {
  setCategory
} from 'reducer/actions/categories'
import {
  setRoute
} from 'reducer/actions/route'

const Label = styled(NoSelect)`${props => `
  font-size: ${props.theme.rem()};
  color: ${props.theme.color};
  font-weight: 600;
  margin-bottom: ${props.theme.rem(.5)};
  margin-top: ${props.theme.rem()};
`}`

const TextResult = styled.span`
  min-height: 21px;
  display: block;
  font-size: ${props => props.theme.rem()};
  color: ${props => props.theme.text.color};
  font-weight: ${props => props.theme.text.weight};
  margin-left: ${props => props.theme.rem(.5)}
`

const StyledFullPage = styled.div`${ props => `
  padding: 0 ${props.theme.rem()};
  flex: 1;
`}`

// Component
export const QuerySection = () => {

  const {
    activeRequest_reason,
    activeCategory,
    result: { categories },
    entities: {
      categories: categoryNodes,
      request_reasons: request_reasonNodes
    },
    dispatch
  } = useContext(AppContext)

  const { dispatch: routeDispatch } = useContext(RouteContext)

  const [next, setNext] = useState(false)

  useEffect(() => {
    const categorySelected = activeCategory?.uuid ? true : false
    const reasonSelected = activeRequest_reason?.uuid ? true : false
    setNext(categorySelected && reasonSelected)
  }, [activeCategory, activeRequest_reason])

  const QueryNavigation =  (
      <CSSTransition
        in={next}
        timeout={200}
        classNames="button"
        unmountOnExit
      >
        <NavButtonNext 
          onClick={() => routeDispatch(setRoute('/providers'))}
          style={{ marginLeft: 'auto' }}
        >
          Select a Provider
        </NavButtonNext>
      </CSSTransition>
  )
  

  return (
    <FullPageContainer navigation={QueryNavigation}>
      <StyledFullPage>
        <QueryGroup>
          <Label>
            1. Have you visited us before?
          </Label>
          <CategoryToggle.Container>
            {
              !categories && <CategoryToggle.Toggle active={true}><Loading /></CategoryToggle.Toggle>
            }
            {
              categories?.map((c, i) => {
                let node = categoryNodes[c]
                return node.request_reasons.length > 0 ? (
                  <CategoryToggle.Toggle
                    key={node.uuid}
                    active={activeCategory.uuid === node.uuid}
                    onClick={() => dispatch(setCategory({ uuid: node.uuid}))}
                  >
                    {node.category_name}
                  </CategoryToggle.Toggle>
                ) : null
              })
            }
          </CategoryToggle.Container>
        </QueryGroup>
        <CSSTransition
          in={activeCategory?.uuid}
          timeout={200}
          classNames="button"
          unmountOnExit
        >
          <QueryGroup>
            <Label>
              2. What can we help you with?
            </Label>
            <ReasonSelect
              options={activeCategory?.request_reasons?.map(r => request_reasonNodes[r])}
            />
          </QueryGroup>

        </CSSTransition>
        <CSSTransition
          in={activeRequest_reason?.uuid}
          timeout={200}
          classNames="button"
          unmountOnExit
        >
          <QueryGroup>
            <Label>
              Appointment Length
            </Label>
            <TextResult>{activeRequest_reason ? activeRequest_reason?.appt_length / 60000 : '0'}min</TextResult>
            <Label>
              Description
            </Label>
            <TextResult>{activeRequest_reason ? activeRequest_reason?.description : '...'}</TextResult>
          </QueryGroup>
        </CSSTransition>
      </StyledFullPage>
    </FullPageContainer>
  )
}