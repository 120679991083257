// CONSTANTS
import {
  GET_SLOTS_REQUEST,
  GET_SLOTS_SUCCESS,
  GET_SLOTS_REJECTED,
  SET_SELECTED_SLOT
} from 'reducer/types/slots'

import { baseUrl } from 'utils/data/baseUrl'

export const getSlotsRequest = ({ api_key, provider, date, reason }) => (dispatch) => {
  dispatch({
    type: GET_SLOTS_REQUEST
  })

  const url = `${baseUrl}${api_key}/availability?pr=${provider}&date=${date}&unit=DAY&arr=${reason}`
  
  var Req = new XMLHttpRequest()
  Req.onload = (e) => {
    const json = JSON.parse(Req.response)
    dispatch(getSlotsSuccess(json))
  }
  Req.open('GET', url)
  Req.send()
}

export const getSlotsSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_SLOTS_SUCCESS,
    data
  })
}

export const getSlotsRejected = () => (dispatch) => {
  dispatch({
    type: GET_SLOTS_REJECTED
  })
}

export const setSelectedSlot = ({ slot, timezone }) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SLOT,
    slot,
    timezone
  })
}