import {
  REQUEST,
  SUCCESS,
  REJECTED
} from 'reducer/types/suffix'
import {
  PUT
} from 'reducer/types/prefix'

// Base CONSTANT for INITIALIZE
export const SUBMIT = 'SUBMIT'

export const PUT_SUBMIT_REQUEST = `${PUT}${SUBMIT}${REQUEST}`
export const PUT_SUBMIT_SUCCESS = `${PUT}${SUBMIT}${SUCCESS}`
export const PUT_SUBMIT_REJECTED = `${PUT}${SUBMIT}${REJECTED}`