// Vendors
import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import { AppContext } from 'context/AppContext'

// Routing
import { RouteContext } from 'context/RouteContext'
import { setRoute } from 'reducer/actions/route'

import * as providerActions from 'reducer/actions/provider'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faIdCardAlt } from '@fortawesome/pro-light-svg-icons';

// Private Components
import {
  Header,
  IconContainer,
  InfoContainer,
  Name,
  Role,
  Body,
  ButtonLink,
  HiddenSM,
} from 'components/ProviderCard/Private'

// Style Wrapper
const ProviderCardStyled = styled.div`
  overflow: hidden;
  position: relative !important;
  font-weight: ${props => props.theme.text.weight};
  color: ${props => props.theme.text.color};
  border-top: ${props => props.theme.border()};
  cursor: pointer;

  &:last-child {
    border-bottom: ${props => props.theme.border()}
  }
`
ProviderCardStyled.defaultProps = {
  theme: {
    light: '#edebe9',
    borderRadius: `${.25 * 16}px`,
    lightSecondary: '#605e5c'
  }
}

// Component
export const ProviderCard = ({ provider, handleReset, resetKey}) => {
  
  const { dispatch } = useContext(AppContext)
  const { dispatch: routeDispatch } = useContext(RouteContext)

  const handleSelection = () => {
    dispatch(providerActions.setProvider({ provider }))
    routeDispatch(setRoute('/calendar'))
  }

  return (
    <ProviderCardStyled onClick={handleSelection}>
        <Header>
          <IconContainer image={provider.profile_image_url}>
          {!provider.profile_image_url && <FontAwesomeIcon icon={faIdCardAlt} size="2x"/>}
          </IconContainer>
          <InfoContainer>
            <Name>
              {provider.name}
            </Name>
            <Role>
              {provider.role}
            </Role>
          </InfoContainer>
          <ButtonLink>
            <FontAwesomeIcon icon={faCalendarAlt} /><HiddenSM> View openings</HiddenSM>
          </ButtonLink>
        </Header>    
        {provider.description && <Body>{provider.description}</Body>}
    </ProviderCardStyled>
  )
}