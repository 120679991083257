import React from 'react'
import { ThemeProvider } from 'styled-components'
import { themeDefaultState as theme } from 'context/ThemeContext'
import { AppContext, appDefaultState } from 'context/AppContext'
import { RouteContext, routeDefaultState } from 'context/RouteContext'
import { useReducerWithThunk } from 'utils/reducer'
import { AppReducer } from 'reducer/AppReducer'
import { RouteReducer } from 'reducer/RouteReducer'

import { CSSReset } from 'components/CSSReset'
import { PMOBW } from 'components/PMOBW'

const App = ({ api_key }) => {

  const [AppState, dispatch] = useReducerWithThunk(AppReducer, appDefaultState)

  const [RouteState, routeDispatch] = useReducerWithThunk(RouteReducer,routeDefaultState)

  return (
    <AppContext.Provider value={{ ...AppState, api_key, dispatch}}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <RouteContext.Provider value={{...RouteState, dispatch: routeDispatch}}>
          <PMOBW />
        </RouteContext.Provider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default App