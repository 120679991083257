import styled from 'styled-components'

const Body = styled.div`
  padding: ${props => props.theme.rem()};
`

Body.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`,
  }
}

export {
  Body
}