import 'utils/polyfill'
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import * as serviceWorker from './registerServiceWorker';

const root = document.getElementById('Mojo-Request-Appointment');

const newArraySearch = window.location.search.slice(1).split('&')

let searchObject = {}

for (let i = 0; i < newArraySearch.length; i++) {
  const [key, value] = newArraySearch[i].split('=')
  searchObject[key] = value
}

const rootAPIKey = searchObject['api'] || root.getAttribute('data-company') || ''

ReactDOM.render(<App api_key={rootAPIKey} />, root);

serviceWorker.unregister();
