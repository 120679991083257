import styled from 'styled-components'

// Components
import { NoSelect } from 'components/NoSelect'

const SelectContainer = styled(NoSelect)`
position: relative;
width: 100%;
& > :first-child {
  border-top: 1px solid ${props => props.theme.primary};
  border-top-left-radius: ${props => props.theme.borderRadius};
  border-top-right-radius: ${props => props.theme.borderRadius};
}
& > :last-child {
  border-bottom-left-radius: ${props => props.theme.borderRadius};
  border-bottom-right-radius: ${props => props.theme.borderRadius};
}
`

SelectContainer.defaultProps = {
  theme: {
    primary: '#009ade',
    borderRadius: `${.25 * 16}px`
  }
}

export { 
  SelectContainer
}