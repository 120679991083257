import React from 'react'
import styled from 'styled-components'

export const NavigationBar = styled.div`
  bottom: 30px;
  width: 100%;
  margin-top: ${props => props.theme.rem(4)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 ${props => props.theme.rem()};
  border-top: ${props => props.theme.border()};
  background: white;
  z-index: 1;
`