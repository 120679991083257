import styled from 'styled-components'

const Collapse = styled.div`${props => `
  max-height: ${props.show ? `1500px` : `0px`};
  width: 100%;
  transition: ${props.show ? '.6s' : '.3s'} max-height;
  overflow: hidden;
`}`

Collapse.defaultProps = {
  show: false
}

export { Collapse }