const phone = {
  validate: (v) => {
    v = v.replace(/\D/g, '')
    return v.length >= 7
  },
  error: 'Please enter a valid phone number.'
}

export {
  phone
}