// actionTypes
import * as actionTypes from 'reducer/types/route'

export const setRoute = (route) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_ROUTE,
      route
    })
  }
}