const sanatizePhone = (phone_number) => {
  phone_number = phone_number.replace(/\D/g, '');
  const _phone = {}
  _phone.area_code = phone_number.substr(0, 3);
  if (phone_number.length > 3) {
    _phone.phone_number = phone_number.substr(3, 7);
  } else {
    _phone.phone_number = ''
  }
  if (phone_number.length > 10) {
    _phone.extension = phone_number.substr(10, 10);
  } else {
    _phone.extension = ''
  }
  return _phone;
}

export {
  sanatizePhone
}