import styled from 'styled-components'

const HiddenMD = styled.div`${props => `
  display: none;
  text-decoration: underline;
  ${props.theme.media.lg(`
    margin-left: 4px;
    display: inline-block;
  `)}
`}`

HiddenMD.defaultProps = {
  theme: {
    media: {
      lg: (s) => s
    }
  }
}

export {
  HiddenMD
}