import styled from 'styled-components'

const Sidebar = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: ${props => props.theme.border()};
  background: #f4f3f2;
  padding-bottom: ${props => props.theme.rem()};
  ${props => props.theme.media.md(`
    border-right: ${props.theme.border()};
    border-bottom: 0px;
  `)}
`

Sidebar.defaultProps = {
}

export {
  Sidebar
}