import styled from 'styled-components'

const SectionNav = styled.div`${props => `
  top: 0;
  padding: 0 ${props.theme.rem()};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f3f2;
  height: 75px;
  position: relative;
  overflow: hidden;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-right: 16px solid #f4f3f2;
  }
`}`

const Title = styled.h2`${props => `
  margin: 0;
  color: ${props.theme.color};
  white-space: nowrap;
  font-size: ${props.theme.rem(1.625)};
  letter-spacing: 0;
  font-weight: 700;
  
  ${props.theme.media.sm(`
    font-size: ${props.theme.rem(2)};
  `)}
`}`

const SubTitle = styled.div`${props => `
  margin-top: ${ props.theme.rem(-.375)};
  color: ${props.theme.color};
  white-space: nowrap;

  font-size: ${props.theme.rem(.875)};
  
  ${props.theme.media.sm(`
    font-size: ${props.theme.rem()};
  `)}
`}`
SectionNav.Title = Title;
SectionNav.SubTitle = SubTitle;

export {
  SectionNav
}