// Vendors
import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from 'context/AppContext'
import './ProviderSection.css'

// Routing
import { RouteContext } from 'context/RouteContext'
import { setRoute } from 'reducer/actions/route'

import { ProviderCard } from 'components/ProviderCard'
import { Help } from 'components/Help'
import { NoSelect } from 'components/NoSelect'
import { SectionNav } from 'components/SectionNav'
import { NavButtonBack } from 'components/Button'
import { FullPageContainer } from 'components/FullPageContainer'

import { 
  TransitionGroup,
  CSSTransition
} from 'react-transition-group'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTooth } from '@fortawesome/pro-light-svg-icons';

export const ProviderSection = () => {

  const [search, setSearch] = useState('')
  const [mount, setMount] = useState(false)
  
  const {
    activeRequest_reason,
    activeCategory,
    entities: {
      providers: providerNodes,
    }
  } = useContext(AppContext)

  const { dispatch: routeDispatch } = useContext(RouteContext)

  const filterProviders = (activeRequest_reason) => {
    if (activeRequest_reason?.providers) {
      return activeRequest_reason.providers.reduce((res, p) => {
        let node = providerNodes[p];
        if (node?.name?.toLowerCase().includes(search.toLocaleLowerCase())) {
          res.push(node)
        }
        return res;
      }, [])
    } else {
      return []
    }
  }

  const [searchRes, setSearchRes] = useState(filterProviders(activeRequest_reason))

  useEffect(() => {
    setSearchRes(filterProviders(activeRequest_reason))
  }, [search, activeRequest_reason])

  const ProviderNavigation = (
    <NavButtonBack 
      onClick={() => routeDispatch(setRoute('/'))}
      type='link'
    >
      Back to Services
    </NavButtonBack>
  )
  return (
    <FullPageContainer navigation={ProviderNavigation}>
      <SectionNav>
        <div>
          <NoSelect as={SectionNav.Title}>Select a Provider</NoSelect>
          <NoSelect as={SectionNav.SubTitle}>
            {activeCategory?.category_name} / {activeRequest_reason?.name}
          </NoSelect>
        </div>
      </SectionNav>
      <div style={{flex: 1}}>
      {
        searchRes.length > 0
          ?
        <TransitionGroup>
          {searchRes.map(node => (
            <CSSTransition
              key={node.uuid}
              timeout={200}
              classNames="provider"
            >
              <ProviderCard
                key={`${node.uuid}${mount}`}
                resetKey={mount}
                handleReset={setMount}
                provider={node}
              />
            </CSSTransition>
          ))}
          </TransitionGroup>
          :
          <Help>
            <FontAwesomeIcon icon={faTooth} size="2x" />
            <Help.Header>No Results</Help.Header>
            <Help.Sub>No providers matched your search criteria.</Help.Sub>
            <Help.Sub>Adjust your search terms and try again.</Help.Sub>
          </Help>
      }
      </div>
    </FullPageContainer>
  )
}