// Vendors
import React from 'react'
import styled from 'styled-components'
import { NoSelect } from 'components/NoSelect'

import Logo from 'MojoLogo.png'

const PoweredByContainer = styled(NoSelect)`
  bottom:0;
  width: 100%;
  grid-area: poweredby;
  padding: 0px ${props => props.theme.rem()};
  display: flex;
  align-items: center;
  color: ${props => props.theme.text.color};
  font-weight: ${props => props.theme.text.weight};
  font-size: ${props => props.theme.rem(.75)};
  background: #f4f3f2;
  border-top: ${props => props.theme.border()};
  justify-content: center;
`

const Brand = styled.img`
  width: 100px;
`


export const PoweredBy = ({style={}}) => {
  return (
    <PoweredByContainer style={style}>
      Powered by: <Brand src={Logo} />
    </PoweredByContainer>
  )
}