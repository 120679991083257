import styled from 'styled-components'

const Option = styled.div`
  padding: ${props => props.theme.rem(.5)} ${props => props.theme.rem(1)};
  font-size: ${props => props.theme.rem()};
  color: ${props => props.theme.primary};
  border-bottom: 1px solid ${props => props.theme.primary};

  &:hover {
    background: ${props => props.theme.primary}22;
  }
  &:last-child {
    border-bottom: none;
  }
  `
Option.defaultProps = {
  theme: {
    rem: (n) => `${n * 16}px`,
    primary: '#009ade'
  }
}

export {
  Option
}