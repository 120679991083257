import { useEffect, useRef } from 'react'

/*
  desc:
    Custom hook for executing on non-initial render.
    Persistant nature of a Ref allows us to initialize `false` on render
    and set `true` once so following executions of the effect execute.

  args:
    fn: function
    deps: var[]

  example useage:
    const [count, setCount] = useState(0)

    useUpdateEffect(() => {
      ...
    }, [count]);
*/

export const useUpdateEffect = (fn, deps) => {
  // useRef persists across re-renders
  const didMount = useRef(false)

  useEffect(() => {
    // Check if didMount
    if (didMount.current) {
      // execute function
      fn()
    } else {
      // Set didMount to true
      didMount.current = true;
    }
  }, deps)
}