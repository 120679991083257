import styled from 'styled-components'

const Accent = styled.span`
  color: ${props => props.theme.accent}
`

Accent.defaultProps = {
  theme: {
    accent: '#80c342'
  }
}

export {
  Accent
}