// Vendors
import update from 'immutability-helper'

// hook utilities
import { useDebounce } from 'utils/hooks'
import { useUpdateEffect } from 'utils/hooks'
import { useReducerWithThunk } from 'utils/reducer'

// CONSTANTS
import { REQUEST, SUCCESS } from 'reducer/types/suffix'
const VALIDATION = 'VALIDATION'
const VALIDATION_REQUEST = `${VALIDATION}${REQUEST}`
const VALIDATION_SUCCESS = `${VALIDATION}${SUCCESS}`

// Actions
// Request Validation Action
const validationRequest = (value, rules = [{validate: () => true, error: 'error'}]) => (dispatch) => {
  dispatch({
    type: VALIDATION_REQUEST
  })

  const errors = rules.reduce((result, rule) => {
    if (!rule.validate(value)) {
      result.push(rule.error)
    }
    return result
  }, [])

 dispatch(validationSuccess(errors))

}
// Validation Completed Action
const validationSuccess = (errors) => (dispatch) => {
  dispatch({
    type: VALIDATION_SUCCESS,
    errors
  })
}

// Default reducer state
const validationDefaultState = {
  isValidated: false,
  errors: [],
  validating: false
}

// Validation Reducer
const validationReducer = (state, action) => {

  switch(action.type) {
    
    case VALIDATION_REQUEST: {
      return update(state, {
        $set: {
          isValidated: false,
          errors: [],
          validating: true
        }
      });
    
    }
    case VALIDATION_SUCCESS: {

      const errors = action.errors

      return update(state, {
        $set: {
          isValidated: true,
          errors,
          validating: false
        }
      });
    }

    default: {
      return state;
    }
  }
}

// Custom useInputValidationHook
function useInputValidation(value='', rules = [{ validate: () => true, error: 'error' }], submit = false) {
  const [state, dispatch] = useReducerWithThunk(validationReducer, validationDefaultState)
  const debouncedValue = useDebounce(value, 500)

  useUpdateEffect(() => {
    dispatch(validationRequest(debouncedValue, rules))
  }, [debouncedValue, submit])

  return state
}

export { useInputValidation }