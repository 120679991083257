import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'

import { NavigationBar } from 'components/NavigationBar'

const FullPageWrapper = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
`

export const FullPageContainer = (props) => {

  const pageRef = useRef(null)
  const scrollToRef = () => {
    const boundingRect = pageRef.current.getBoundingClientRect()
    const elPos = boundingRect.top + window.pageYOffset - 100

    if (boundingRect.top < 0) {
      window.scrollTo({ top: elPos, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    scrollToRef()
  }, [])

  return (
    <FullPageWrapper ref={pageRef}>
        {props.children}
      <NavigationBar>
        {props.navigation}
      </NavigationBar>
    </FullPageWrapper>
  )
}