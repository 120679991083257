// vendors
import update from 'immutability-helper'

// CONSTANTS
import * as actionTypes from 'reducer/types/route'

// Initial state of the reducer
import { routeDefaultState } from 'context/RouteContext'


// Each state mutation executed by the reducer
const setRoute = (state, action) => {
  const { route } = action;
  return update(state, {
    route: { $set: route }
  })
}

// The reducer
export const RouteReducer = (state = routeDefaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROUTE: return setRoute(state, action)
    default: return state
  }
}