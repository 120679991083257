import styled from 'styled-components'

// Components
import { HoverPointer } from 'components/HoverPointer'

const OptionsContainer = styled(HoverPointer)`
  background: white;
  width: 100%;
  border: 1px solid ${props => props.theme.primary};
  border-top: none;
  opactiy: ${props => props.open ? 1 : 0};
  transition: .3s opactiy;
  z-index: 1;
`

OptionsContainer.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`,
    primary: '#009ade'
  },
  open: false
}

export {
  OptionsContainer
}