// CONSTANTS
import {
  GET_SLOTS_REQUEST,
  GET_SLOTS_SUCCESS,
  GET_SLOTS_REJECTED
} from 'reducer/types/slots'

export const SlotReducer = (state, action) => {

  switch (action.type) {
    case GET_SLOTS_REQUEST: {
      return state;
    }
    case GET_SLOTS_SUCCESS: {
      return action.data;
    }
    case GET_SLOTS_REJECTED: {
      return state;
    }
    default: {
      return state
    }

  }

}