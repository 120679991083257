import styled from 'styled-components'

import { HoverPointer } from 'components/HoverPointer'

const Name = styled(HoverPointer)`
  font-weight: bold;
  color: ${props => props.theme.primary};
  font-size: ${props => props.theme.rem(1.25)}
`

Name.defaultProps = {
  theme: {
    primary: '#009ade',
    rem: (n = 1) => `${n * 16}px`
  }
}

export {
  Name
}