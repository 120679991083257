import styled from 'styled-components'
import { NoSelect } from 'components/NoSelect'

const Help = styled(NoSelect)`${props => `
border-top: ${props.theme.border()};
text-align: center;
padding: ${props.theme.rem(4)} ${props.theme.rem(1.5)};
`}`

const Sub = styled.p`${props => `
  color: ${props.theme.text.color};
  font-weight: ${props.theme.text.weight};
  margin: 0;
`}`

const Header = styled.div`${props => `
  font-size: ${props.theme.rem(2)};
  font-weight: bold;
`}`

Help.Sub = Sub;
Help.Header = Header;

export {
  Help
}