import styled from 'styled-components'

import { HoverPointer } from 'components/HoverPointer'

const ButtonLink = styled(HoverPointer)`${props => `
  margin-left: auto;
  margin-right: ${props.theme.rem()};
  color: ${props.theme.primary};
  font-size: ${props.theme.rem()};
  ${props.theme.mediaInverse.xs(`
    border-radius: ${props.theme.borderRadius};
    background: ${props.theme.accent};
    color: white;
    padding: ${props.theme.rem(.5)} ${props.theme.rem(1)};
    &:hover {
      background: ${props.theme.accent}ee;
    }
  `)}
`}`

ButtonLink.defaultProps = {
  theme: {
    rem: (n) => `${n * 16}px`,
    primary: '',
    borderRadius: `${.25 * 16}px`,
    accent: '',
    media: {
      xs: (s) => `${s}`
    }
  }
}

export {
  ButtonLink
}