import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { HoverPointer } from 'components/HoverPointer'

const TimeSlotWrapper = styled(HoverPointer)`${
  props => `
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props.selected ? props.theme.accent : props.theme.primary};
    color: white;
    border-radius: ${props.theme.borderRadius};
    padding: ${props.theme.rem(.5)} ${props.theme.rem()};
  `
}
`
const IconWrapper = styled.div`

`
const TimeWrapper = styled.div`
  white-space: nowrap
`

export const TimeSlot = ({selected, time, timezone, setTime = () => {}}) => {
  return (
    <TimeSlotWrapper selected={selected} onClick={setTime}>
      <IconWrapper>
        <FontAwesomeIcon icon={faClock}/>
      </IconWrapper>
      <TimeWrapper>
        {moment(time).tz(timezone).format('h:mm a')}
      </TimeWrapper>
    </TimeSlotWrapper>
  )
}