import styled from 'styled-components'

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${props => props.theme.rem(4)};
  margin-right: ${props => props.theme.rem()};
  margin-left: ${props => props.theme.rem()};
  background: ${props => props.theme.light}33;
  border-radius: 50%;
  ${props => props.image && `
    background: url(${props.image});
    background-size: cover;
    background-position: center;
  `}
`
IconContainer.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`,
    light: '#edebe9',
    borderRadius: `${.25 * 16}px`
  },
  image: undefined
}

export {
  IconContainer
}