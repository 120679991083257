import React from 'react'

import { InputText } from 'components/InputText'
import { Label } from 'components/Label'
import { Error } from 'components/Error'

const InputGroupText = ({ label, placeholder, icon, value, setValue, validation }) => (
  <div>
    <Label>
      {label}
    </Label>
    <InputText
      placeholder={placeholder}
      icon={icon}
      value={value}
      setValue={setValue}
      error={validation.errors.length > 0}
    />
    {
      validation.errors.map(e => <Error key={e}>{e}</Error>)
    }
  </div>
)

export { InputGroupText }