import styled from 'styled-components'

const Spacer = styled.span`
  margin-left: ${props => props.theme.rem(.5)};
  margin-right: ${props => props.theme.rem(.5)};
`

Spacer.defaultProps = {
  theme: {
    rem: (n = 1) => `${n * 16}px`
  }
}

export {
  Spacer
}