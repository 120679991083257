import styled from 'styled-components'

const PlaceholderText = styled.div`
    font-size: ${props => props.theme.rem()};
    color: ${ props => props.theme.lightSecondary};
    font-style: italic;
  `
PlaceholderText.defaultProps = {
  theme: {
    lightSecondary: '#605e5c'
  }
}

export {
  PlaceholderText
}