import styled from 'styled-components'

const SlotsContainer = styled.div`${props => `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${props.theme.rem()};
  padding: ${props.theme.rem()};
  border-top: ${props.theme.border('transparent')};
  border-bottom: ${props.theme.border('transparent')};
  ${props.error ? `
    border-color: ${props.theme.orange};
    background: ${props.theme.orange}16;
  ` : ''}
`}`

export { SlotsContainer }