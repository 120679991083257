import {
  REQUEST,
  SUCCESS,
  REJECTED
} from 'reducer/types/suffix'
import {
  GET
} from 'reducer/types/prefix'

// Base CONSTANT for INITIALIZE
export const INITIALIZE = 'INITIALIZE'

export const GET_INITIALIZE_REQUEST = `${GET}${INITIALIZE}${REQUEST}`
export const GET_INITIALIZE_SUCCESS = `${GET}${INITIALIZE}${SUCCESS}`
export const GET_INITIALIZE_REJECTED = `${GET}${INITIALIZE}${REJECTED}`