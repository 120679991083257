import styled from 'styled-components'

const QueryGroup = styled.div`

`

QueryGroup.defaultProps = {

}

export {
  QueryGroup
}