import styled from 'styled-components'

const Textarea = styled.textarea`${props => `
  width: 100%;
  overflow: auto;
  resize: none;
  border-radius: ${props.theme.borderRadius};
  font-size: ${props.theme.rem()};
  padding-left: ${props.theme.rem(.5)};
  padding-right: ${props.theme.rem(.5)};
  border: ${props.theme.border()};
  height: 100px;
  padding-top: ${props.theme.rem(.375)};
  font-family: ${props.theme.text.family};
`}`

export { Textarea }