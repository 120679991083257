// vendors
import update from 'immutability-helper'
import moment from 'moment-timezone';

// CONSTANTS
import * as initializeTypes from 'reducer/types/intialize'
import * as categoryTypes from 'reducer/types/categories'
import * as dialogTypes from 'reducer/types/success_dialog'
import * as reasonTypes from 'reducer/types/request_reasons'
import * as providerTypes from 'reducer/types/provider'
import * as slotTypes from 'reducer/types/slots'
import * as submitTypes from 'reducer/types/submit'

const setProvider = (state, action) => {
  const { provider } = action
  return update(state, {
    selectedProvider: { $set: provider }
  })
}

const getInitializeSuccess = (state, action) => {
  const { data: { result, entities } } = action
  
  return update(state, {
    api_key: { $set: result?.api_key },
    timezone: { $set: result?.timezone },
    name: { $set: result?.name },
    entities: { $set: entities },
    result: {
      categories: { $set: result?.categories }
    }
  })
}  

const setSuccessDialogShow = (state, action) => {
  const { show } = action
  return update(state, {
    successDialog: {
      $merge: {
        show
      }
    }
  })
}

const setCategory = (state, action) => {
  const { uuid } = action
  return update(state, {
    $merge: {
      activeCategory: state.entities?.categories?.[uuid],
      activeRequest_reason: undefined
    }
  })
}

const setRequestReason = (state, action) => {
  const { uuid } = action
  return update(state, {
    $merge: {
      activeRequest_reason: state.entities?.request_reasons?.[uuid]
    }
  })
}

const setSelectedSlot = (state, action) => {
  const { slot } = action
  return update(state, {
    $merge: {
      selectedSlot: slot
    }
  })
}

const setSubmitSuccess = (state, action) => {
  const { successDialog } = action
  return update(state, {
    activeCategory: { $set: { }},
    activeRequest_reason: { $set: '' },
    successDialog: { $set: {...successDialog } }
  })
}

export const AppReducer = (state, action) => {

  switch(action.type) {
    case providerTypes.SET_PROVIDER: return setProvider(state, action)
    case initializeTypes.GET_INITIALIZE_SUCCESS: return getInitializeSuccess(state, action)
    case dialogTypes.SET_SUCCESS_DIALOG_SHOW: return setSuccessDialogShow(state, action)
    case submitTypes.PUT_SUBMIT_SUCCESS: return setSubmitSuccess(state, action)
    case categoryTypes.SET_CATEGORY: return setCategory(state, action)
    case reasonTypes.SET_REQUEST_REASON: return setRequestReason(state, action)
    case slotTypes.SET_SELECTED_SLOT: return setSelectedSlot(state, action)
    default: return state
  }

}